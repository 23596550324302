<template>
  <a-layout>
    <div style="background-color:#fff;">
      <a-layout-content style="padding:0;">
        <div style=" background: #fff; padding:0px 20px 0; margin:15px 0 0 0;">
          <div class="yj-content" style="height: 100%">
            <a-row type="flex" style="padding:10px 0;" justify="space-between">
              <a-col :span="16">
                <span>类型：</span>
                <a-radio-group v-model="RadioMaterialType" @change="radioChangeContent">
                  <a-radio-button value="-1">全部</a-radio-button>
                  <a-radio-button value="5">海报</a-radio-button>
                  <a-radio-button value="1">文章</a-radio-button>
                  <a-radio-button value="3">H5</a-radio-button>
                </a-radio-group>

                <!--<a-select label-in-value
              style="width: 120px;margin-left:10px"
              v-model="CreateType"
              placeholder="请选择"
              id="CreateTypeName"
              @change="selectChangeContent">

        <a-select-option la value="-1">
            全部
        </a-select-option>
        <a-select-option value="0">
            自创
        </a-select-option>
        <a-select-option value="1">
            订阅
        </a-select-option>
                </a-select>-->
                <span style="margin-left:20px">创作类型：</span>
                <el-select
                  v-model="CreateType"
                  placeholder="请选择"
                  @change="selectChangeContent"
                  style="width:120px;margin-left:10px;"
                >
                  <el-option label="全部" value="-1"></el-option>
                  <el-option label="自创" value="0"></el-option>
                  <el-option label="订阅" value="1"></el-option>
                </el-select>

                <a-input
                  id="name"
                  contextmenu="2455"
                  :v-model="txt_val"
                  @change="name_change"
                  :value="name"
                  style="width: 260px; margin: 0 15px"
                  placeholder="输入关键词搜索"
                ></a-input>
                <a-button @click="search">查询</a-button>
              </a-col>
              <a-col :span="8" align="right">
                <Add :closeDialog="closeDialog" :MaterialType="URLMaterialType"></Add>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 25]" style="margin: 15px 0">
              <a-col :span="4" v-for="info in tableData" :key="info.ID">
                <a-card
                  hoverable
                  style="overflow:hidden; position:relative"
                  @click="NewShowDetail(info)"
                >
                  <img
                    slot="cover"
                    class="thumb-img"
                    :src="Getsrc(info)"
                    @click="NewShowDetail(info)"
                  />
                  <div class v-if="info.AwardPoolID!=null">
                    <span v-if="info.AwardPoolStatus==1" class="yh-card bhyhq">优惠卷</span>
                    <span v-if="info.AwardPoolStatus==2" class="yh-card noyhq">优惠卷</span>
                  </div>

                  <div class v-if="info.MaterialType==1">
                    <span v-if="info.CreateTypeStatus==0" class="yh-articlecard articlebhyhq">自创</span>
                    <span v-if="info.CreateTypeStatus==1" class="yh-articlecard articlebhyhq">订阅</span>
                  </div>

                  <a-card-meta  :title="info.Name.length>0?info.Name:info.Author">
                    <template slot="description">
                      <a-row type="flex" justify="space-around">
                        <a-col :span="7">
                          <a-button type size="small" :key="info.ID" @click="NewShowDetail(info)">
                            <a-icon type="edit"></a-icon>编辑
                          </a-button>
                        </a-col>
                        <a-col :span="7">
                          <a-button
                            type
                            size="small"
                            :key="info.ID"
                            @click.stop="MaterialTabs(info)"
                          >
                            <a-icon type="bar-chart"></a-icon>数据
                          </a-button>
                        </a-col>
                        <a-col :span="7">
                          <a-button
                            type="danger"
                            ghost
                            size="small"
                            :key="info.ID"
                            @click.stop="UpdateState(info)"
                          >
                            <a-icon type="delete"></a-icon>删除
                          </a-button>
                        </a-col>
                        <a-col :span="24" align="right">
                          <div style="margin:auto;text-align:left;margin-top:12px;">
                            <a-tag
                              color="blue"
                              style="border:none; margin-left:20px"
                              v-if="info.PublishTime==null"
                            >未推送</a-tag>
                            <span v-else>
                              <a-icon type="clock-circle" style="margin-left:10px" />
                              <a-tag color="blue" style="border:none;">已推送</a-tag>
                              <span style="font-size:13px">{{TimeStamptoDateTime(info.PublishTime)}}</span>
                            </span>
                          </div>
                        </a-col>
                      </a-row>
                    </template>
                  </a-card-meta>
                </a-card>
              </a-col>
            </a-row>
            <a-row :gutter="[20, 20]">
              <a-col :span="24" align="center">
                <a-pagination
                  v-model="current"
                  :total="total"
                  :defaultPageSize="defaultPageSize"
                  :showTotal="showTotal"
                  @change="pagerChange"
                />
              </a-col>
            </a-row>
            <div v-if="DetailVisible">
              <div style="height:100%;">
                <div v-if="DetailVisible">
                  <Detail
                    :closeDetail="closeDetail"
                    :MaterialType="MaterialType"
                    :ID="ID"
                    :PushID="PushID"
                    :IDType="0"
                    :disabled="disabled"
                  ></Detail>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
    </div>
  </a-layout>
</template>
<script>
import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
import local from "@/Plugin//local.js";
import Add from "@/Views/MaterialModule/M2Material/Add";
import Detail from "@/Views/MaterialModule/M2Material/Detail";
export default {
  name: "MyList",
  data() {
    return {
      name: "",
      isFirstEnter: false,
      ordervalue: "0",
      txt_val: "",
      clear: false,
      tableData: [],
      current: 1,
      total: 0,
      thispage: 0,
      defaultPageSize: 12,
      showTotal: total => `共 ${total} 条数据`,
      addVisible: false,
      DetailVisible: false,
      MaterialType: -1,
      ID: "",
      disabled: false,
      PushID: "0",
      URLMaterialType: -1,
      RadioMaterialType: "-1",
      CreateType: "-1",
      CreateTypeName: ""
    };
  },
  props: {
    //组件属性
    //path:Number
  },
  methods: {
    //方法
    name_change: function(item) {
      var self = this;
      self.clear = true;
      self.name = item.target.value;
      self.txt_val = self.name;
      local.setlocal("name", self.name);
    },
    getTableData: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/NewGetMaterialCustomerList",
        data: {
          pageIndex: self.current,
          PageCount: self.defaultPageSize,
          MaterialType: self.RadioMaterialType,
          PublishStatus: -1,
          Name: self.name,
          CreateType: self.CreateType
        },
        OnSuccess: function(data) {
          self.tableData = data.data.Data;
          self.total = data.data.Total;
          self.defaultPageSize = data.data.RowCount;
          self.name=data.data.name;
          self.current =
            self.thispage == 0 ? data.data.PageIndex : self.thispage;
        }
      };
      http.Post(op);
    },

    Getsrc: function(info) {
      var rt = info.Logo;
      if (rt == null) {
        rt = "#";
      } else {
        if (rt.indexOf("upload2") < 0) {
          rt = rt.replace(/upload\//g, "upload2/");
        }
      }
      return rt;
    },
    pagerChange: function(item) {
      var self = this;
      self.clear = true;
      self.current = self.thispage = item;
      let str =
        self.$router.history.current.fullPath.indexOf("&") != -1 ? "&" : "?";
      let page =
        self.current != undefined && self.current != ""
          ? `${str}page=${self.current}`
          : "";
      local.setlocal("page", parseInt(self.thispage));
      self.$router.push(`/MaterialModule/Store/MyList${page}`);

      self.getTableData();
    },
    TimeStamptoDateTime: function(e) {
      return util.TimeStamptoDateTime(e, "yyyy-MM-dd hh:mm");
    },
    ToFixed: function(e) {
      return util.ToFixed(e);
    },
    radioChangeContent: function(item) {
      var self = this;
      self.clear = false;
      self.RadioMaterialType = item.target.value;
      local.setlocal("RadioMaterialType",self.RadioMaterialType);
      local.setlocal("page",1);
      self.current = self.thispage = 1;
      self.getTableData();
    },
    selectChangeContent: function(item) {
      var self = this;
      self.clear = true;
      self.CreateType = item;
      self.current = self.thispage = 1;
      local.setlocal("CreateType", self.CreateType);
      local.setlocal("page",1);
      self.getTableData();
    },
    search: function() {
      var self = this;
      self.current = self.thispage = 1;
      self.getTableData();
    },
    closeDialog(e) {
      var self = this;
      if (e) {
        self.current = 1;
        self.getTableData();
      }
      self.addVisible = false;
    },
    closeDetail(e) {
      var self = this;
      if (e) {
        self.current = 1;
        self.getTableData();
      }
      self.DetailVisible = false;
    },
    ShowDetail(e) {
      var self = this;
      self.ID = e.ID;
      self.disabled = e.PublishStatus == 1 ? true : false;
      self.DetailVisible = true;
    },
    NewShowDetail: function(e) {
      var self = this;
      self.clear = true;
      if (e.MaterialType == 1) {
        //this.ShowDetail(e);
        //return;
        self.$router.push({
          name: "M2Material_Extension",
          query: {
            ID: e.ID,
            
          }
        });
        return;
      }
      self.$router.push({
        name: "Store_MyListShow",
        query: {
          mid: e.ID,
          TmplID: e.TemplateID,
            MaterialType: e.MaterialType,
          
        }
      });
    },
    UpdateState(e) {
      var self = this;
      self.clear = true;
      this.$confirm({
        title: "提示",
        content: "是否删除" + e.Name,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          if (e.ClassID == 8) {
            var op1 = {
              url:
                "/MarketingModule/MKTPunchClock/EditInteractionInfoPublishStatus",
              data: {
                ID: e.ID,
                PublishStatus: 2
              },
              OnSuccess: function() {
                self.current = 1;
                self.getTableData();
              }
            };
            http.Post(op1);
          }
          var op = {
            url: "/MarketingModule/Material/UpdateStatus",
            data: {
              ID: e.ID,
              PublishStatus: 2
            },
            OnSuccess: function() {
              self.current = 1;
              self.getTableData();
              self.$message.success("删除成功");
            }
          };
          http.Post(op);
        },
        onCancel() {}
      });
    },
    MaterialTabs: function(e) {
      var self = this;
      self.clear = true;
      self.$router.push({
        name: "Store_MaterialTabs",
        query: {
          ID: e.ID,
          ClassID: e.ClassID,
        }
      });
    }

    //cancel: function () {
    //    this.$router.push({
    //        name: "Store_MyList", query: {
    //        }
    //    });
    //    //this.$router.go(-1)
    //},
  },
  computed: {
    //计算属性
    // "retu_str"(str){
    //   return str.split('.')[1];
    // }
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
    Add,
    Detail
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
    this.isFirstEnter = true;
  },
  beforeMount: function() {
    //生命周期，组装前
  },

  activated: function() {
    this.$setgoindex();
  },
  mounted: function() {
    //生命周期，组装完成
    var self = this;
    self.current = self.thispage =
      local.getlocal("page") != null ? parseInt(local.getlocal("page")): 1;
    self.name = self.txt_val =
      local.getlocal("name") != null ? local.getlocal("name") : "";
    self.CreateType =
      local.getlocal("CreateType") != null ? local.getlocal("CreateType") :"-1";
    self.RadioMaterialType =
      local.getlocal("RadioMaterialType") != null
        ? local.getlocal("RadioMaterialType")
        : "-1";
    self.getTableData();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
    if (!this.clear) {
      local.clearlocal();
    }
  },
  OnSuccess: function(data) {
    self.tableData = data.data.Data;
    self.total = data.data.Total;
    self.defaultPageSize = data.data.RowCount;
    self.current = data.data.PageIndex;
  }
};
</script>
<style scoped>
.card-opt {
  display: flex;
  color: #666;
  justify-content: space-between;
}

.thumb-img {
  height: 150px;
  object-fit: cover;
  outline: none;
  background: url("../../../../src/assets/nourl.png");
  background-size: cover;
}

.card-opt > span {
  text-align: center;
}

.yh-card {
  position: absolute;
  right: -45px;
  top: 10px;
  display: inline-block;
  padding: 2px 45px;
  letter-spacing: 2px;
  font-size: 8px;
  transform: rotateZ(45deg);
  border-radius: 5px 0 0 5px;
}

.bhyhq {
  background: #f20;
  color: #fff;
}

.noyhq {
  background: #999;
  color: #ddd;
}

.yh-articlecard {
  position: absolute;
  right: -45px;
  top: 5px;
  display: inline-block;
  padding: 2px 45px;
  letter-spacing: 2px;
  font-size: 8px;
  transform: rotateZ(45deg);
  border-radius: 5px 0 0 5px;
}
.articlebhyhq {
  background: #f20;
  color: #fff;
}
</style>