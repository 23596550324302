<template>
    <div>
        <!--<a-dropdown :trigger="['click']">
            <a-button type="primary"> 新增素材 </a-button>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Material.ID">
                {{ Material.Name }}<a-icon type="down" />
            </a>
            <a-menu slot="overlay">
                <template v-for="item in MaterialList">
                    <a-menu-item :key="item.ID">
                        <a @click="SetShowKey(item.ID)">{{ item.Name }}</a>
                    </a-menu-item>
                </template>
            </a-menu>
        </a-dropdown>-->
        <a-button type="primary" @click="SetShowKey(1)" class="AddMaterial">新增文章</a-button>
        <a-button type="primary" @click="SetShowKey(5)" class="AddMaterial">新增海报</a-button>

        <a-button type="primary" @click="SetShowKey(3)" class="AddMaterial">新增H5</a-button>
        <div v-if="ShowKey==0">
            <Moment :close="close" :ID="ID" :PushID="ID" :IDType=0 :disabled="false"></Moment>
        </div>
        <div v-if="ShowKey==1">
            <Extension :close="close" :ID="ID" :PushID="ID" :IDType=0 :disabled="false"></Extension>
        </div>
        <div v-if="ShowKey==3">
            <H5 :close="close" :ID="ID" :PushID="ID" :IDType=0 :disabled="false"></H5>
        </div>
        <div v-if="ShowKey==5">
            <Poster :close="close" :ID="ID" :PushID="ID" :IDType=0 :disabled="false"></Poster>
        </div>
    </div>
</template>
<script>
    import Moment from '@/Views/MaterialModule/components/Moment';
    import Extension from '@/Views/MaterialModule/components/AddExtension';
    import H5 from '@/Views/MaterialModule/components/NewH5';
    import Poster from '@/Views/MaterialModule/components/NewPoster';
    export default {
        name: "Add",
        data() {
            return {
                ShowKey: -1,
                ID: "0",
                MaterialList: [{ Name: "海报", ID: 5 }, { Name: "推广文章", ID: 1 }, { Name: "H5模版", ID: 3 }],
                Material: { Name: "新增", ID: -1 },
            };
        },
        props: {//组件属性
            closeDialog: {
                type: Function,
                default: null
            },
            MaterialType: Number,
        },
        methods: {//方法
            SetShowKey: function (e) {
                this.ShowKey = e;
                if (e == 5) {
                    this.$router.push({
                        name: "Store_PackageList", query: {
                            MaterialType: e,
                        }
                    })
                }
                if (e == 3) {
                    this.$router.push({
                        name: "Store_PackageList", query: {
                            MaterialType: e,
                        }
                    })
                }
                if (e == 1) {
                    this.$router.push({
                        name: "M2Material_AddExtension", query: {
                        }
                    })
                }
            },
            close(e) {
                var self = this;
                self.ShowKey = -1;
                self.closeDialog(e);
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Moment,
            Extension,
            H5,
            Poster
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style scoped>
    .AddMaterial {
        margin-right: 10px;
    }
</style>